import React from 'react';
import './App.css';

function App() {
    return (
        <div className="App">
            <header className="App-header">
                <div className="App-perspective">
                    <h1 className="App-title">
                        OCTAL
                    </h1>
                    <h1 className="App-title">
                        IMAGE
                    </h1>
                    <p>
                        Web Technology consultancy.<br/>
                        Expertise in Typescript and AWS.
                    </p>
                </div>
            </header>
            <footer>
                <table>
                    <tr>
                        <td>Enterprise Number</td>
                        <td>0829.799.069</td>
                    </tr>
                    <tr>
                        <td>Address</td>
                        <td>Colonel Silvertopstraat 33.03</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>2850 Boom</td>
                    </tr>
                    <tr>
                        <td>email</td>
                        <td><a href='mailto:sam@octalimage.com'>sam@octalimage.com</a></td>
                    </tr>
                </table>
            </footer>
        </div>
    );
}

export default App;
